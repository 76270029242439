@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('./components/slider.css');
@import url('./components/default-loader.css');
@import url('./components/quick-search.css');
@import url('./components/modal.css');

body {
  width: 100%;

  @apply font-body;
  @apply text-primary-black;
  @apply bg-background;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-heading;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  @apply underline-offset-[0.25em];
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

input:focus {
  box-shadow: none !important;
}

input[type='search']::-webkit-search-cancel-button {
  display: none;
}

/* Current values consider the presence of the Announcement bar */
body:has(#header-container) div.body-section {
  @apply pt-172 md:pt-164 xl:pt-198;
}

/* This custom style is needed to control the chevron color when the slider is disabled */
/* We can't use the original "arrow" class on the <span> because the Swiper lib creates an arrow visual */
.swiper-button-disabled .span-chevron {
  @apply text-neutral-3;
}

@layer utilities {
  /* Hide Arrows From Input Number */
  .no-spinner {
    -moz-appearance: textfield;
  }

  .no-spinner::-webkit-outer-spin-button,
  .no-spinner::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .masonry-2 {
    column-gap: 32px;
    column-count: 2;
  }
  .masonry-3 {
    column-gap: 32px;
    column-count: 3;
  }
  .masonry-4 {
    column-gap: 32px;
    column-count: 4;
  }

  .text-shadow {
    text-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
  }

  .font-bold {
    font-family: var(--font-transgourmet-bold);
  }

  .shadow-custom {
    box-shadow: 0px 1px 5px 0px rgba(28, 28, 28, 0.2);
  }

  .shadow-custom-2 {
    box-shadow: 0px -2px 4px 0px #d8d8d8;
  }

  /* custom shadow only applied to the top */
  .shadow-custom-top {
    box-shadow: 0 5px 5px -5px #d8d8d8;
  }

  .debug {
    background-color: rgba(255, 0, 0, 0.5);
    outline: 1px dashed hsl(320 100% 50%);
  }
}
